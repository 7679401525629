export const checkWebcamAvailability = async () => {
    try {
      // Get a list of all connected media devices
      const devices = await navigator.mediaDevices.enumerateDevices();
  
      // Filter devices to check if any video input devices are available
      const hasWebcam = devices.some(device => device.kind === "videoinput");
  
      return hasWebcam;
    } catch (error) {
      console.error("Error checking webcam availability:", error);
      return false; // Assume no webcam is available in case of an error
    }
  };